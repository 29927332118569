<template>
  <div>
    <vs-row class="mt-5">
      <vs-col vs-w="12">
        <div class="helloCard py-4 px-6 flex items-center justify-between rounded-md mb-5">
          <p class="text-xl text-primary">
            Olá 👋, <strong>{{ userName }}</strong>!
            {{ fullDate }}
          </p>

          <vs-button
            icon-pack="feather"
            icon="icon-chevrons-right"
            icon-after
            to="/apps/inbox"
          >
            Acessar Inbox
          </vs-button>
        </div>
      </vs-col>
    </vs-row>
    <div class="w-full mt-4" v-if="$acl.check('manager')">
      <div class="vx-row">
        <CardContainer
          cardTitle="Campanhas"
          cardHeader="Conversas"
          cardIcon="SendIcon"
          key="cardCampaign"
          :statsDiff="0"
          :cardValue="statsData.conversationCampaign"
          :modalText="statsData.conversationCampaign + ' é o número de conversas de campanhas.'"
        />

        <CardContainer
          cardHeader="Conversas"
          cardTitle="Aguardando atendimento"
          :cardValue="statsData.waitingAttendant"
          :cardIcon="'ClockIcon'"
          :modalText="`${statsData.waitingAttendant} conversas estão esperando atendimento`"
          :statsDiff="0"
          :key="`card1-${chatContactsKey}`"
        />

        <CardContainer
          cardHeader="Conversas"
          cardTitle="Em atendimento"
          :cardIcon="'MessageSquareIcon'"
          :modalText="`${statsData.inAttendance} conversas estão em atendimento nesse momento`"
          :cardValue="statsData.inAttendance"
          :statsDiff="0"
        />

        <CardContainer
          cardHeader="Conversas"
          cardTitle="Total"
          :cardValue="statsData.inAttendance + statsData.waitingAttendant + statsData.conversationCampaign"
          :cardIcon="'UsersIcon'"
          :modalText="statsData.inAttendance + statsData.waitingAttendant + ' é o total de conversas ativas'"
          :statsDiff="0"
        />
      </div>

      <div class="vx-row max-h-full" v-if="$acl.check('manager')">
        <CardUsersOnline
          :key="`card1-${chatContactsKey}`"
          :cardTitle="'Usuários da Conta'"
          :cardData="statsData.userList"
        />
        <CardCardConversationByIntention
          :key="`card2-${chatContactsKey}`"
          cardTitle="Por intenção"
          cardHeader="Conversas"
          :cardData="statsData.conversationByIntention"
        />
        <CardConversationByEntity
          :key="`card3-${chatContactsKey}`"
          cardTitle="Por entidade chave"
          cardHeader="Conversas"
          :cardData="statsData.conversationByEntity"
        />
        <CardConversationByAttendent
          :key="`card4-${chatContactsKey}`"
          cardHeader="Conversas"
          cardTitle="Por atendente"
          :cardData="statsData.conversationByAttendant"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CardContainer from "./components/CardContainer.vue";
import CardCardConversationByIntention from "./components/CardConversationByIntention.vue";
import CardConversationByAttendent from "./components/CardConversationByAttendent.vue";
import CardConversationByEntity from "./components/CardConversationByEntity.vue";
import CardUsersOnline from "./components/CardUsersOnline.vue";

export default {
  components: {
    CardContainer,
    CardCardConversationByIntention,
    CardConversationByAttendent,
    CardConversationByEntity,
    CardUsersOnline,
  },
  data() {
    return {
      interval: null,
      fullDate: "",
      userName: this.$store.state.user.name.split(" ")[0],
      statsData: {
        conversationCampaign: 0,
        waitingAttendant: 0,
        inAttendance: 0,
        userList: [],
        conversationByIntention: [],
        conversationByEntity: [],
        conversationByAttendant: []
      },
      chatContactsKey: 0,
      dataAvailable: false,
    };
  },
  created() {
    this.interval = setInterval(this.realTime, 1000);
    if (this.$acl.check("manager")) this.getHomeData();
  },
  methods: {
    async getHomeData() {
      await this.$http.get("/p/chat/inbox/new-dashboard/home", {
        params: {
          acc: this.$store.state.acc.current_acc.id,
        },
      }).then((res) => {
        this.dataAvailable = true;
        this.statsData = res.data.data;
        this.chatContactsKey = 1;
      })
    },
    realTime() {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, "0");
      const month = today.toLocaleString("pt-BR", { month: "long" });
      const year = today.getFullYear();
      const hour = String(today.getHours()).padStart(2, "0");
      const minutes = String(today.getMinutes()).padStart(2, "0");
      this.fullDate = `Agora é ${day} de ${month} de ${year} às ${hour}:${minutes}`;
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.vx-row {
  display: flex;
  justify-content: flex-end;
}

.helloCard {
  background-image: linear-gradient(0deg, rgba(115, 103, 240, 0.21), rgba(115, 103, 240, 0.21)), linear-gradient(0deg, #7367F0FF, #7367F0FF);
  background-size: auto, 5px 100%;
  background-repeat: repeat, no-repeat;
}
</style>
